<template>
  <AdminWrapper>
    <div class="mb-[24px]">忘记密码</div>
    <a-form layout="vertical" :hideRequiredMark="true" autocomplete="false" class="forget-form">
      <a-form-item label="手机号码" v-bind="validateInfos.cellphone">
        <a-input autocomplete="false" placeholder="请输入手机号码" v-model:value="formData.cellphone"></a-input>
      </a-form-item>
      <a-form-item label="验证码" v-bind="validateInfos.verifyCode">
        <div class="flex">
          <a-input autocomplete="false" placeholder="输入验证码" v-model:value="formData.verifyCode"></a-input>
          <a-button :disabled="!!verifyCodeTime" class="ml-[10px]" @click="formVerifyCodeClick">{{
            verifyCodeTime ? `重新获取${verifyCodeTime}s` : '获取验证码'
          }}</a-button>
        </div>
      </a-form-item>
      <a-form-item label="输入新密码" v-bind="validateInfos.newPassword">
        <a-input
          autocomplete="false"
          placeholder="6 - 16 位密码，区分大小写"
          type="password"
          v-model:value="formData.newPassword"
        ></a-input>
      </a-form-item>
      <a-form-item autocomplete="false" label="确认密码" v-bind="validateInfos.confirmPassword">
        <a-input placeholder="确认密码" type="password" v-model:value="formData.confirmPassword"></a-input>
      </a-form-item>
      <a-form-item>
        <a-button style="width: 100%" type="primary" :loading="submitLoading" @click.prevent="submitClick"
          >确认</a-button
        >
      </a-form-item>
    </a-form>
  </AdminWrapper>
</template>
<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { Form, message } from 'ant-design-vue'
import AdminWrapper from '@admin/components/wrapper.vue'
import { useRouter } from 'vue-router'

import { useAjax } from '@vue-mfe/utils'

const router = useRouter()

const useForm = Form.useForm
let verifyCodeTime = ref(0)

const tiemStars = () => {
  verifyCodeTime.value = 60
  const timer = setInterval(
    () => {
      if (verifyCodeTime.value > 0) {
        verifyCodeTime.value--
      } else {
        clearInterval(timer)
      }
    },
    1000
  )
}

const formData = reactive(
  {
    cellphone: '',
    newPassword: '',
    confirmPassword: '',
    verifyCode: ''
  }
)
const { validate, validateInfos } = useForm(
  formData,
  {
    cellphone: [
      { required: true, message: '请输入手机号码' },
      { len: 11, message: '请输11位手机号', trigger: 'blur' },
      { pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入合格的手机号', trigger: 'blur' }
    ],
    verifyCode: [
      { required: true, message: '请输入验证码' },
      { len: 4, message: '请输入4位验证码' }
    ],
    newPassword: [
      {
        required: true,
        // @ts-ignore
        validator: async (rule: any, value: string) => {
          const reg2 = /[\u4e00-\u9fa5/\s]{1,}/g
          if (value === '') return Promise.reject('请输入密码')
          else if (value.length < 6 || value.length > 12) return Promise.reject('请输6-12位号码')
          else if (reg2.test(value)) {
            return Promise.reject('不支持空格、中文')
          } else {
            Promise.resolve()
          }
        }
      }
    ],
    confirmPassword: [
      {
        required: true,
        // @ts-ignore
        validator: async (rule: any, value: string) => {
          if (value !== formData.newPassword) return Promise.reject('两次密码输入不一致')
        }
      }
    ]
  }
)
const formVerifyCodeClick = () => {
  validate('cellphone').then(
    () => {
      useAjax(
        {
          action: 'GET /supplier/security/password/cellphone/verify/code',
          params: () => ({ cellphone: formData.cellphone }),
          success () {
            message.success('验证码已发送')
            tiemStars()
          }
        }
      )
    }
  )
}
const { run: submit, loading: submitLoading } = useAjax(
  {
    action: `POST /supplier/security/password/cellphone`,
    params: () => [
      {
        cellphone: formData.cellphone,
        newPassword: formData.newPassword,
        verifyCode: formData.verifyCode
      }
    ],
    success () {
      message.success('密码已更新')
    },
    lazy: true
  }
)
const submitClick = () => {
  validate(['cellphone', 'verifyCode', 'newPassword']).then(
    () => {
      submit().then(
        (res) => {
          if (res.status === 200) {
            router.push({ path: '/admin/authenticate' })
          }
        }
      )
    }
  )
}
</script>
<style scoped>
.forget-form :deep(.ant-input),
.forget-form :deep(.ant-btn) {
  height: 40px;
  font-size: 16px;
}
</style>
